import React from 'react'
import {
  Box,
  Skeleton,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { UpsellCategoryProps } from './types'
import { useUserUpsells } from './queries'
import { UpsellPurchaseItem } from './PurchaseItem'

export const UpsellCategories = () => {
  const { mappedCategories } = useUserUpsells()
  return (
    <Box display="flex" flexDirection="column" gap="40px">
      {mappedCategories.map((category) => {
        return <UpsellCategory key={category.id} {...category} />
      })}
    </Box>
  )
}

export const UpsellCategory: React.FC<UpsellCategoryProps> = ({
  id,
  title,
  required,
  items,
}) => {
  const { data, dict } = useUserUpsells()
  const theme = useTheme()
  const isSizeMD = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Box display="flex" flexDirection="column" gap="5px">
      <Box display="flex" flexDirection="row" gap="13px">
        <Typography
          style={{
            fontSize: 22,
            fontWeight: 600,
          }}
        >
          {title}
        </Typography>
        {required && (
          <Typography style={{ fontSize: 20 }}>(please select one)</Typography>
        )}
      </Box>
      {!!data?.length ? (
        <Box
          display="flex"
          flexDirection="row"
          gap="40px"
          flexWrap="wrap"
          alignItems="flex-start"
          mt="30px"
        >
          {items.map((itemId) => {
            const item = dict[itemId]
            if (!item) return null
            return (
              <UpsellPurchaseItem
                key={itemId}
                data={dict[itemId]}
                peers={required ? items : []}
              />
            )
          })}
        </Box>
      ) : (
        <Box
          display="flex"
          gap="40px"
          height={`${isSizeMD ? 300 : 300}px`}
          sx={{
            flexDirection: 'row',

            [theme.breakpoints.down('md')]: {
              flexDirection: 'column',
            },
          }}
          pt="30px"
        >
          <Skeleton
            width="100%"
            variant="rectangular"
            animation="pulse"
            height="50%"
          />
          <Skeleton
            width="100%"
            variant="rectangular"
            animation="pulse"
            height="50%"
          />
        </Box>
      )}
    </Box>
  )
}
