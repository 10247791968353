import React from 'react'
import { Box, Container } from '@mui/material'
import CaptchaProvider from '@talentinc/gatsby-theme-ecom/components/Providers/Captcha'
import { UpsellFormProvider } from '@talentinc/gatsby-theme-ecom/components/NewUpsell/UpsellProvider'
import UpsellPage from '@talentinc/gatsby-theme-ecom/components/NewUpsell/UpsellPage'

export const UpsellTemplate = () => {
  return (
    <Container>
      <Box display="flex" justifyContent="center">
        <Box
          display="flex"
          alignItems="center"
          sx={{ width: { xs: '100%', sm: 'calc(100% - 10rem)' } }}
        >
          <UpsellFormProvider>
            <CaptchaProvider>
              <UpsellPage />
            </CaptchaProvider>
          </UpsellFormProvider>
        </Box>
      </Box>
    </Container>
  )
}
