import React, { Dispatch, createContext, useContext, useReducer } from 'react'

export type UpsellFormState = Record<string, any>

const INITIAL_STATE = {
  activePackages: new Set(['standard-delivery']),
} as UpsellFormState

export type UpsellFormAction =
  | {
      type: 'TOGGLE_PACKAGE'
      field?: string
      value: string | boolean
      peers?: string[]
    }
  | { type: 'RESET_PACKAGES' }

export const UpsellFormContext = createContext<
  | {
      state: UpsellFormState
      dispatch: Dispatch<UpsellFormAction>
    }
  | undefined
>(undefined)

export const UpsellFormProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const [state, dispatch] = useReducer(
    (state: UpsellFormState, action: UpsellFormAction): UpsellFormState => {
      switch (action.type) {
        case 'TOGGLE_PACKAGE': {
          if (typeof action.value !== 'string') {
            throw new Error('Field must be a string')
          }

          if (typeof action?.peers) {
            action?.peers?.forEach((peer) => {
              if (state.activePackages.has(peer)) {
                state.activePackages.delete(peer)
              }
            })
          }

          const activePackages = new Set(state.activePackages)
          activePackages[activePackages.has(action.value) ? 'delete' : 'add'](
            action.value
          )

          return {
            ...state,
            activePackages,
          }
        }
        case 'RESET_PACKAGES':
          return INITIAL_STATE
        default:
          throw new Error(`Unknown action: ${action}`)
      }
    },
    INITIAL_STATE
  )

  return (
    <UpsellFormContext.Provider value={{ state, dispatch }}>
      {children}
    </UpsellFormContext.Provider>
  )
}

export const useUpsellForm = () => {
  const context = useContext(UpsellFormContext)

  if (!context) {
    throw new Error('useUpsellForm must be used within a UpsellFormProvider')
  }

  return context
}
