import { Upsell, UpsellCategoryProps } from './types'

export const STANDARD_DELIVERY = 'standard-delivery'

export const SINGLE_STANDARD_DELIVERY = {
  id: 9999999,
  name: 'Standard Delivery',
  plan_code: STANDARD_DELIVERY,
  price: 0,
  original_price: 0,
  short_pitch: '',
  very_short_pitch: '',
  icon_url: 'Rush.svg',
  package: {
    id: 27,
    name: 'Standard Delivery',
    description: null,
    item_names: 'Standard Delivery',
    item_type_keys: 'interview-prep',
    created_at: '2020-06-11T15:34:21Z',
    updated_at: '2020-06-11T15:34:21Z',
  },
  items: [
    {
      id: 48,
      item_id: 17,
      package_id: 27,
      quantity: 1,
      name: 'Standard Delivery',
      item_type_key: 'standard-delivery',
      upsell_priority: 2,
    },
  ],
  created_at: '2017-07-24T15:06:02Z',
  purchases_today: 0,
  currency_code: 'USD',
  currency_symbol: '$',
  oms_fulfillable_items: 1,
  upsell_priority: 2,
  bundle_groups: [],
  bundle: null,
} as Upsell

export const FIXED_RETURN = [
  {
    id: 919,
    name: 'TopInterview Expert Coaching',
    plan_code: 'tzatInR',
    price: 119,
    original_price: 149,
    short_pitch:
      'Refine and polish your interview skills through TopInterview Expert Coaching. You’ll work one-on-one with a professional coach on a mock interview and get objective feedback on your body language and communication skills. You’ll also receive TopInterview’s exclusive interview guidebook, full of proven interview strategies and techniques to help you think on your feet.',
    very_short_pitch:
      'Refine and polish your interview skills through TopInterview Expert Coaching.',
    icon_url: 'TopInterview.svg',
    package: {
      id: 27,
      name: 'Interview Prep [US]',
      description: null,
      item_names: 'Interview Prep Session',
      item_type_keys: 'interview-prep',
      created_at: '2020-06-11T15:34:21Z',
      updated_at: '2020-06-11T15:34:21Z',
    },
    items: [
      {
        id: 48,
        item_id: 17,
        package_id: 27,
        quantity: 1,
        name: 'Interview Prep Session',
        item_type_key: 'interview-prep',
        upsell_priority: 2,
      },
    ],
    created_at: '2017-07-24T15:06:02Z',
    purchases_today: 0,
    currency_code: 'USD',
    currency_symbol: '$',
    oms_fulfillable_items: 1,
    upsell_priority: 2,
    bundle_groups: [],
    bundle: null,
  },
  {
    id: 134,
    name: 'Professional LinkedIn Service',
    plan_code: 'gaqGt4T',
    price: 99,
    original_price: 125,
    short_pitch:
      'After a hiring manager views your resume, the first place they visit is your LinkedIn profile. Why not take advantage of our professional writers to wow all the employers looking at your profile! With a professionally written profile, you can expect more interviews and job offers.\r\n',
    very_short_pitch: '',
    icon_url: 'LinkedIn.svg',
    package: {
      id: 2,
      name: 'LinkedIn Service Document',
      description: '',
      item_names: 'LinkedIn Makeover',
      item_type_keys: 'linkedin-document',
      created_at: '2020-06-11T15:34:20Z',
      updated_at: '2020-07-16T16:11:50Z',
    },
    items: [
      {
        id: 3,
        item_id: 15,
        package_id: 2,
        quantity: 1,
        name: 'LinkedIn Makeover',
        item_type_key: 'linkedin-document',
        upsell_priority: 2,
      },
    ],
    created_at: '2013-08-13T19:47:33Z',
    purchases_today: 0,
    currency_code: 'USD',
    currency_symbol: '$',
    oms_fulfillable_items: 1,
    upsell_priority: 2,
    bundle_groups: [],
    bundle: null,
  },
  {
    id: 732,
    name: 'Phone Consultation',
    plan_code: 'lvBKW3T',
    price: 50,
    original_price: 0,
    short_pitch:
      'The phone consultation service gives clients the opportunity to speak with their writer for 30 minutes to discuss the document created. If this add-on service is purchased after a writer has already been assigned, there is a chance the order may need to be reassigned to a new writer who provides this service.',
    very_short_pitch:
      "Speak with your writer on the phone about your career after you've received your first draft.",
    icon_url: 'PhoneConsultation.svg',
    package: {
      id: 7,
      name: 'Phone Call',
      description: null,
      item_names: 'Phone Call',
      item_type_keys: 'phone-call',
      created_at: '2020-06-11T15:34:20Z',
      updated_at: '2020-06-11T15:34:20Z',
    },
    items: [
      {
        id: 10,
        item_id: 16,
        package_id: 7,
        quantity: 1,
        name: 'Phone Call',
        item_type_key: 'phone-call',
        upsell_priority: 2,
      },
    ],
    created_at: '2016-12-02T20:22:13Z',
    purchases_today: 0,
    currency_code: 'USD',
    currency_symbol: '$',
    oms_fulfillable_items: 1,
    upsell_priority: 2,
    bundle_groups: [],
    bundle: null,
  },
  {
    id: 776,
    name: 'Recruiter.com Resume Distribution Service',
    plan_code: 'POQbzX',
    price: 50,
    original_price: 0,
    short_pitch:
      'How would you like to have not just one job recruiter, but thousands, looking out for great career opportunities for you? Recruiter.com does exactly that. This service provides a platform for you to distribute your resume to a very large pool of recruiters actively looking to fill positions. If your resume meets their needs, they will then contact you!',
    very_short_pitch:
      'Express resume-posting features allow you to post to 50 job sites at once!',
    icon_url: 'Recruiter.svg',
    package: {
      id: 26,
      name: 'Recruiter.com',
      description: null,
      item_names: 'Recruiter.com',
      item_type_keys: 'recruiter-service',
      created_at: '2020-06-11T15:34:21Z',
      updated_at: '2020-06-11T15:34:21Z',
    },
    items: [
      {
        id: 47,
        item_id: 65,
        package_id: 26,
        quantity: 1,
        name: 'Recruiter.com',
        item_type_key: 'recruiter-service',
        upsell_priority: 1,
      },
    ],
    created_at: '2017-04-18T20:11:11Z',
    purchases_today: 0,
    currency_code: 'USD',
    currency_symbol: '$',
    oms_fulfillable_items: 0,
    upsell_priority: 1,
    bundle_groups: [],
    bundle: null,
  },
  {
    id: 64,
    name: 'Resume Posting Service',
    plan_code: 'RMyW8QF',
    price: 40,
    original_price: 50,
    short_pitch:
      'This service will give you the opportunity to post your resume on hiring websites and job boards with minimal effort! After your account is set up and your resume is downloaded to the site, you can select the industries you are interested in and your resume will be posted directly on relevant websites.',
    very_short_pitch:
      'Express resume-posting features allow you to post to 50 job sites at once!',
    icon_url: 'ResumeRabbit.svg',
    package: {
      id: 13,
      name: 'Resume Rabbit',
      description: null,
      item_names: 'Resume Rabbit',
      item_type_keys: 'posting-service',
      created_at: '2020-06-11T15:34:20Z',
      updated_at: '2020-06-11T15:34:20Z',
    },
    items: [
      {
        id: 16,
        item_id: 64,
        package_id: 13,
        quantity: 1,
        name: 'Resume Rabbit',
        item_type_key: 'posting-service',
        upsell_priority: 1,
      },
    ],
    created_at: '2014-09-19T12:43:25Z',
    purchases_today: 0,
    currency_code: 'USD',
    currency_symbol: '$',
    oms_fulfillable_items: 0,
    upsell_priority: 1,
    bundle_groups: [],
    bundle: null,
  },
  {
    id: 508,
    name: 'Follow-Up Thank You Letter',
    plan_code: 'euLeYeG',
    price: 35,
    original_price: 50,
    short_pitch:
      'Other than the cover letter, the second most important piece of your personal branding package would be an effective thank-you note. The best way to make sure you get the offer is with an effective thank you note to follow-up with after your interview.',
    very_short_pitch:
      'Ensure you get the offer with an effective thank you note to follow-up with after your interview.',
    icon_url: 'ThankYouLetter.svg',
    package: {
      id: 11,
      name: 'Thank You Letter (Customized)',
      description: null,
      item_names: 'Thank You Letter',
      item_type_keys: 'thank-you-letter',
      created_at: '2020-06-11T15:34:20Z',
      updated_at: '2020-06-11T15:34:20Z',
    },
    items: [
      {
        id: 14,
        item_id: 9,
        package_id: 11,
        quantity: 1,
        name: 'Thank You Letter',
        item_type_key: 'thank-you-letter',
        upsell_priority: 1,
      },
    ],
    created_at: '2014-08-25T20:21:38Z',
    purchases_today: 0,
    currency_code: 'USD',
    currency_symbol: '$',
    oms_fulfillable_items: 1,
    upsell_priority: 1,
    bundle_groups: [],
    bundle: null,
  },
  {
    id: 150,
    name: 'Professionally Written Cover Letter',
    plan_code: 'zonSOVf',
    price: 35,
    original_price: 50,
    short_pitch:
      "We will prepare a detailed and highly focused cover letter tailored directly to your job search goals. The cover letter will be drafted in such a way that you should be able to use it each time you submit your resume to a prospective employer.  The resume's cover letter greatly impacts how you are perceived by employers.",
    very_short_pitch:
      'Get a highly focused cover letter tailored directly to your job search goals.',
    icon_url: 'CoverLetter.svg',
    package: {
      id: 6,
      name: 'Cover Letter',
      description: null,
      item_names: 'Cover Letter',
      item_type_keys: 'cover-letter',
      created_at: '2020-06-11T15:34:20Z',
      updated_at: '2020-06-11T15:34:20Z',
    },
    items: [
      {
        id: 9,
        item_id: 11,
        package_id: 6,
        quantity: 1,
        name: 'Cover Letter',
        item_type_key: 'cover-letter',
        upsell_priority: 1,
      },
    ],
    created_at: '2014-05-19T15:36:12Z',
    purchases_today: 0,
    currency_code: 'USD',
    currency_symbol: '$',
    oms_fulfillable_items: 1,
    upsell_priority: 1,
    bundle_groups: [],
    bundle: null,
  },
  {
    id: 43,
    name: '48-Hour Resume Rush Service',
    plan_code: 'xkwOvCI',
    price: 75,
    original_price: 0,
    short_pitch: 'Get the first draft of your resume within two business days!',
    very_short_pitch: 'Get your resume in as little as 48 hours',
    icon_url: 'Rush.svg',
    package: {
      id: 9,
      name: 'Expedited 48-Hour Turnaround',
      description: null,
      item_names: 'Expedited 48-Hour Turnaround',
      item_type_keys: 'rush-order',
      created_at: '2020-06-11T15:34:20Z',
      updated_at: '2020-06-11T15:34:20Z',
    },
    items: [
      {
        id: 12,
        item_id: 35,
        package_id: 9,
        quantity: 1,
        name: 'Expedited 48-Hour Turnaround',
        item_type_key: 'rush-order',
        upsell_priority: 1,
      },
    ],
    created_at: '2014-07-22T15:53:45Z',
    purchases_today: 0,
    currency_code: 'USD',
    currency_symbol: '$',
    oms_fulfillable_items: 1,
    upsell_priority: 1,
    bundle_groups: [],
    bundle: null,
  },
] as Upsell[]

// const PRODUCT_ITEMS = {
//   a: {
//     id: 'a',
//     icon: DirectionsRunRounded,
//     name: 'Standard Delivery',
//     details: 'Details go here',
//     price: 0,
//   },
//   b: {
//     id: 'b',
//     icon: DirectionsRunRounded,
//     name: '48-Hour Resume Rush Service',
//     details: 'Details go here',
//     price: 75,
//   },
//   c: {
//     id: 'c',
//     icon: AssignmentInd,
//     name: 'TopInterview Expert Coaching',
//     details: 'Details go here',
//     price: 149,
//   },
//   d: {
//     id: 'd',
//     icon: LinkedIn,
//     name: 'Professional LinkedIn Service',
//     details: 'Details go here',
//     price: 125,
//   },
//   e: {
//     id: 'e',
//     icon: Call,
//     name: 'Phone Consultation',
//     details: 'Details go here',
//     price: 50,
//   },
//   f: {
//     id: 'f',
//     icon: Send,
//     name: 'Follow-Up Thank You Letter',
//     details: 'Details go here',
//     price: 50,
//   },
//   g: {
//     id: 'g',
//     icon: PublishRounded,
//     name: 'Recruiter.com Resume Distribution Service',
//     details: 'Details go here',
//     price: 50,
//   },
//   h: {
//     id: 'h',
//     icon: RateReview,
//     name: 'Resume Posting Service',
//     details: 'Details go here',
//     price: 50,
//   },
// } as Record<string, UpsellProductItem>

// const PRODUCT_CATEGORIES = [
//   {
//     id: 'a',
//     title: '1. Select your delivery speed',
//     items: ['a', 'b'],
//     required: true,
//   },
//   {
//     id: 'b',
//     title: '2. Be prepared',
//     items: ['c', 'd', 'e', 'f'],
//   },
//   {
//     id: 'c',
//     title: '3. Get your resume out there',
//     items: ['g', 'h'],
//   },
// ] as UpsellCategoryProps[]

export const PRODUCT_CATEGORIES = [
  {
    id: 'first-group',
    title: '1. Select your delivery speed',
    items: [SINGLE_STANDARD_DELIVERY.icon_url],
    required: true,
  },
  {
    id: 'second-group',
    title: '2. Be prepared',
    items: ['*'],
  },
  // {
  //   id: 'third-group',
  //   title: '3. Get your resume out there',
  //   items: ['gL0Jl3I'],
  // },
] as UpsellCategoryProps[]
