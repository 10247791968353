// import { FIXED_RETURN } from './data'
import { OrderResponse } from '@talentinc/gatsby-theme-ecom/components/NewCheckout/types'
import axios from 'axios'
import { Upsell } from './types'

export const fetchUserUpsells = async (at: string) => {
  return axios.get<Upsell[]>(`/api/v2/users/${at}/upsells?type=post_purchase`)
}

export const createBraintreeChargeUpsell = async (
  planCodes: string[],
  recaptcha: { token: string },
  giftee?: {
    firstName: string
    lastName: string
    email: string
    note: string
  } | null
) => {
  return await axios.post<OrderResponse>('/api/v2/payments/braintree/charge', {
    planCodes,
    recaptcha,
    ...(giftee ? { giftee } : {}),
  })
}
