import React from 'react'

import CoverLetterIcon from './assets/CoverLetter.svg'
import DefaultIcon from './assets/Default.svg'
import LinkedInIcon from './assets/LinkedIn.svg'
import PhoneConsultationIcon from './assets/PhoneConsultation.svg'
import RecruiterIcon from './assets/Recruiter.svg'
import ResumeRabbitIcon from './assets/ResumeRabbit.svg'
import RushIcon from './assets/Rush.svg'
import ThankYouLetterIcon from './assets/ThankYouLetter.svg'
import TopInterviewIcon from './assets/TopInterview.svg'

const ITEM_MAP_URL = {
  'CoverLetter.svg': CoverLetterIcon,
  'Default.svg': DefaultIcon,
  'LinkedIn.svg': LinkedInIcon,
  'PhoneConsultation.svg': PhoneConsultationIcon,
  'Recruiter.svg': RecruiterIcon,
  'ResumeRabbit.svg': ResumeRabbitIcon,
  'Rush.svg': RushIcon,
  'ThankYouLetter.svg': ThankYouLetterIcon,
  'TopInterview.svg': TopInterviewIcon,
}

export type UpsellItemType = keyof typeof ITEM_MAP_URL

export const UpsellIcon: React.FC<{ id: UpsellItemType }> = ({ id }) => {
  return (
    <img
      style={{
        width: 60,
        height: 60,
        color: '#1a91f0',
      }}
      src={ITEM_MAP_URL[id]}
      alt={id}
    />
  )
}
